<template>
    <BaseScene 
        name="porch-main" 
        :background="ResourceManager.Get('scenes.porchmain')"
        @leave="OnLeaveScene" 
        @enter="OnEnterScene"
    >
        <SceneTrigger scene="stage-pick-perspective" x="-34" y="7" :sound="ResourceManager.Get('shorts.footstepsshort')" :delay="500"/>
        <SceneTrigger scene="porch-piano" x="20" y="0" :sound="ResourceManager.Get('shorts.footstepsshort')" :delay="500"/>

        <VideoTrigger 
            :url="require('@/assets/video/ape.mp4')" 
            x="-11" 
            y="3" 
            :sound="ResourceManager.Get('shorts.enginestart')" 
            mode="local"
            :onEnd="onApeClipEnd"
            :onPause="onApeClipPause"
            :onPlay="onApeClipPlay"
            :onClose="onApeClipPause"
        />

        <URLTrigger 
            url="https://theaterdeslachens.de/de/ueber-uns#verein" 
            x="-25" 
            y="4.5" 
            :sound="ResourceManager.Get('laughs.arek.1')"
        />         
    </BaseScene>
</template>

<script>
import BaseScene from '../BaseScene.vue'
import SceneTrigger from '../triggers/SceneTrigger.vue';
import VideoTrigger from '../triggers/VideoTrigger.vue';
import URLTrigger from '../triggers/URLTrigger.vue';
import { Howl } from 'howler';
import ResourceManager from "@/classes/resource-manager";
import SceneManager from "@/classes/scene-manager";

export default {
    components: {
        BaseScene,
        SceneTrigger,
        VideoTrigger,
        URLTrigger
    },
    mounted() {

    },
    data() {
        return {
            ResourceManager,
            bgm: null
        }
    },    
    methods: {
        OnEnterScene() {
            if (!this.bgm) {
                this.bgm = new Howl({
                    src: [ResourceManager.Get('ambience.foyer')],
                    loop: true,
                    volume: 1
                });
            }
    
            if (!this.bgm.playing()) {
                this.bgm.play();
            }
        },
        OnLeaveScene() {
            this.bgm.stop();
        },
        onApeClipEnd() {
            SceneManager.TransitionToScene("entrance");
        },
        onApeClipPlay() {
            this.bgm.stop();
        },
        onApeClipPause() {
            if (!this.bgm.playing()) {
                this.bgm.play();
            }
        }
    }    
}
</script>